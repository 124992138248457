import { can } from 'rbac'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { FaTwitter } from 'react-icons/fa'

// import Navbar from './components/Navbar'
// import Footer from './components/Footer'
// import Login from './pages/Login'
// import Signup from './pages/Login/Signup'
// import CreateHoldings from './pages/CreateHoldings'
// import Home from './pages/Home'
// import Profile from './pages/Profile'
// import Admin from './pages/Admin'
// import Import from './pages/Import'

// import { getUser } from './services/http'

import './App.css'
import './background.css'
import 'flexboxgrid/css/flexboxgrid.min.css'

function App() {
  // const user = getUser()

  return (
    <div className='App'>
      <div className='stars-container'>
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
      </div>
      <Router>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            padding: 15,
            maxWidth: '100%',
            boxSizing: 'border-box',
          }}
        >
          <div>
            {/* <Navbar />
            <Switch>
              <Route path='/login' component={Login} />
              <Route path='/signup' component={Signup} />
              <Route path='/holdings/add' component={CreateHoldings} />
              <Route path='/profile/:id' component={Profile} />
              {can(user, 'HOLDINGS:APPROVE') && (
                <Route path='/admin' component={Admin} />
              )}
              {can(user, 'HOLDINGS:APPROVE') && (
                <Route path='/import' component={Import} />
              )}
              <Route path='/' exact component={Home} />
              <Route>
                <Redirect to='/' />
              </Route>
            </Switch> */}
            <h1>Thanks for using diamondhands.io</h1>
            <h1>
              You can follow me on twitter{' '}
              <a
                style={{ textDecoration: 'underline', color: '#1DA1F2' }}
                href='https://twitter.com/squishguin'
                target='blank'
              >
                @squishguin
              </a>
            </h1>
            <h1>
              or check out everything else I'm working on{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href='https://akrew.com/'
                target='blank'
              >
                akrew.com
              </a>
            </h1>
            <p>
              Unfortunately the use of the site has come to a halt so I've
              decided to shut it down.
            </p>
          </div>
          {/* <Footer /> */}
        </div>
      </Router>
    </div>
  )
}

export default App
